import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles/";
import Layout from "../components/layout";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "0 0 2rem 0"
  },
  iframe: {
    display: "block",
    background: "#000",
    border: "none",
    width: "100%",
    height: "100vh",
    overflow: "auto"
  },
}))

export default function Listings() {
  const classes = useStyles();
  return (
    <Layout>
      <Grid container className={classes.container}>
        <iframe className={classes.iframe} src="https://amrish-prasher.c21.ca/search" title="listings"></iframe>
      </Grid>
    </Layout>
  )
}